import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const IntroTextSlider = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 13,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    arrows: false,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    pauseOnFocus: false,
    draggable: false,
    focusOnSelect: true,
    touchMove: false,
    responsive: [
      {
        breakpoint: 4000,
        settings: {
          slidesToShow: 12,
        },
      },
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 8,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 15,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 15,
        },
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 12,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      <div>
        <h1>Designer</h1>
      </div>
      <div>
        <h1>Developer</h1>
      </div>
      <div>
        <h1>Creator</h1>
      </div>
      <div>
        <h1>Runner</h1>
      </div>
      <div>
        <h1>Food connoisseur</h1>
      </div>
      <div>
        <h1>Researcher</h1>
      </div>
      <div>
        <h1>Blockchain enthusiast</h1>
      </div>
      <div>
        <h1>Programmer</h1>
      </div>
      <div>
        <h1>Designer</h1>
      </div>
      <div>
        <h1>Developer</h1>
      </div>
      <div>
        <h1>Programmer</h1>
      </div>
      <div>
        <h1>Runner</h1>
      </div>
      <div>
        <h1>Food connoisseur</h1>
      </div>
      <div>
        <h1>Researcher</h1>
      </div>
      <div>
        <h1>Blockchain enthusiast</h1>
      </div>
      <div>
        <h1>Creator</h1>
      </div>
    </Slider>
  );
};

export default IntroTextSlider;
