/* eslint-disable */
import React from "react";
// import { Link } from "gatsby";

const Contact = () => {
    return (
        <>
            <section className="contact" cursor-class="select-contact">
                <div className="left-column">
                    {/* <p><strong>&copy; Timothy Jones</strong></p> */}
                    <h1>Contact</h1>
                </div>
                <div className="right-column">
                    <a href="mailto:hello@timj.design">
                        <div className="marquee-one">
                            <h1>
                                hello@timj.design hello@timj.design hello@timj.design
                            </h1>
                        </div>
                        <div className="marquee-two">
                            <h1>
                                hello@timj.design hello@timj.design hello@timj.design
                            </h1>
                        </div>
                    </a>
                </div>
            </section>
        </>
    );
};

export default Contact;
